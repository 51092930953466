import {FormObject} from '@components/forms/form.t'
import {APIService} from '@services/api'
import {ErpResponse, GetOptions, LookupItem, LookupOptions, LookupResults} from '@shared/erp-api'
import {
  CartItem,
  ErpDomain,
  Permission,
  PriceBundle,
  Profile,
  SearchArticlesItem,
  SearchSaleArticles,
  TaskStatus
} from '@shared/interfaces'
import {ReactNode, createContext, useContext} from 'react'
import * as jsonpatch from 'fast-json-patch'
import {
  TimelineData,
  ChecklistPayload
} from '@components/forms/fields/widgets/task-management-widget/tm.t'
import {DataType} from '@components/dashboard/dashboard.t'
import {
  CartItemDomain,
  CartItemSearchContext
} from '@components/forms/fields/widgets/cart-widget/cart.t'

export const CATALOG_ACTION_ID = '669e5828e037280ff9cbaa6b'
export const REPLACE_ARTICLE_ACTION_ID = '62b9855c00440b37eeecfd2b'
export const PRODUCT_FINDER_ACTION_ID = '62b9855c00440b37eeecfd2b'

export class ErpApiService {
  constructor(private api: APIService, private baseUrl: string) {
    this.baseUrl = baseUrl.replace(/\/+$/, '')
  }

  /**
   * Lookup domain query, used to get a list of items for a given domain
   * @param name The name of the domain
   * @param options The lookup options
   * @returns lookup results
   */
  public lookupDomain<TData = LookupItem>(name: string, options: LookupOptions = {}) {
    const {page = 1, limit = 15, queryParams, ...payload} = options

    return this.api.post<ErpResponse<LookupResults<TData>>>(
      `${this.baseUrl}/${name}/lookup`,
      payload,
      {
        params: {
          page,
          limit,
          ...queryParams
        }
      }
    )
  }

  /**
   * Get a domain item by id
   * @param name The name of the domain
   * @param id The id of the item
   * @param options The get options
   * @returns The domain item
   */
  public getDomainById<T>(name: string, id: string, options: Omit<GetOptions, 'limit'> = {}) {
    const {hydrate} = options

    return this.api.get<ErpResponse<T>>(`${this.baseUrl}/${name}/${id}`, {
      params: {hydrate}
    })
  }

  /**
   * Get all domain items
   * @param name The name of the domain
   * @param options The get options
   * @returns The domain items
   */
  public getDomain<T>(name: string, options: GetOptions = {}) {
    const {hydrate, limit} = options

    return this.api.get<ErpResponse<T[]>>(`${this.baseUrl}/${name}`, {
      params: {
        limit,
        hydrate
      }
    })
  }

  /**
   * Get all erp domains
   * @returns all erp domains
   */
  public getErpDomains() {
    return this.api.get<ErpResponse<ErpDomain[]>>(`${this.baseUrl}/erp/domains`)
  }

  /**
   * Get all permissions
   * @returns all permissions
   */
  public getPermissions() {
    return this.api.get<ErpResponse<Permission[]>>(`${this.baseUrl}/erp/permissions`)
  }

  /**
   * Creates a new item in the specified domain
   * @param domain Domain to create the new item in
   * @param item FormObject representing the new item
   * @returns An Observable that emits the newly created item
   */
  public createDomainItem(domain: ErpDomain, item: FormObject) {
    return this.api.post<ErpResponse<FormObject>>(`${this.baseUrl}/${domain.keyPlural}`, item)
  }

  /**
   * This function will remove a domain item by id.
   * @param {Domain} domain - The domain of the item to remove.
   * @param {string} id - The id of the item to remove.
   * @returns {Observable<ErpResponse<FormObject>>} - The response from the API.
   */
  public removeDomainItemById(domain: ErpDomain, id: string) {
    return this.api.delete<ErpResponse<FormObject>>(`${this.baseUrl}/${domain.keyPlural}/${id}`)
  }

  /**
   * Restores a domain object by id
   * @param domain The domain of the object to restore
   * @param id The id of the object to restore
   */
  public restoreDomainItemById(domain: ErpDomain, id: string) {
    return this.api.post<ErpResponse<FormObject>>(
      `${this.baseUrl}/${domain.keyPlural}/${id}/restore`
    )
  }

  /**
   * Updates an item in a domain by id
   * @param domain domain to update item in
   * @param id id of item to update
   * @param item item to update
   */
  public updateDomainItemById<TData = FormObject>(domain: ErpDomain, id: string, item: TData) {
    return this.api.put<ErpResponse<FormObject>>(`${this.baseUrl}/${domain.keyPlural}/${id}`, item)
  }

  /**
   * Updates an item in a domain by id
   * @param domain domain to update item in
   * @param id id of item to update
   * @param item item to update
   */
  public patchDomainItemById(domain: ErpDomain, id: string, patchs: jsonpatch.Operation[]) {
    return this.api.patch<ErpResponse<FormObject>>(
      `${this.baseUrl}/${domain.keyPlural}/${id}`,
      patchs
    )
  }

  /**
   * Duplicate a domain item by its id.
   *
   * @param domain The domain of the item to duplicate.
   * @param id The id of the item to duplicate.
   * @returns The duplicated item.
   */
  public duplicateDomainItemById(domain: ErpDomain, id: string) {
    return this.api.post<ErpResponse<FormObject>>(
      `${this.baseUrl}/${domain.keyPlural}/${id}/duplicate`
    )
  }

  /**
   * Get catalog filtred data
   *
   * @param {Filters} filter - eg: {articleName: 'Le Pin'}
   * @returns {CatalogData} catalog data
   */
  public getCatalogData<Filters = unknown, CatalogData = unknown>(filters: Filters) {
    return this.api.post<ErpResponse<CatalogData>>(
      `${this.baseUrl}/saleArticles/catalog?action=${CATALOG_ACTION_ID}`,
      filters
    )
  }

  /**
   * Get dashboard data
   */
  public getDashboardData() {
    return this.api.get<ErpResponse<DataType>>(`${this.baseUrl}/dashboard`)
  }

  /**
   * Replace cart article
   *
   * @param {string} articleId - article to update
   * @param {CartItem} cartItem - cartItem to be replaced
   * @param {boolean} isFromCatalog - replacement from catalog or not
   */
  public replaceCartItemArticle(body: {
    saleArticle: string
    supplierArticle?: string
    isFromCatalog: boolean
    cartItem: CartItem | CartItemDomain
    price?: PriceBundle
    searchContext?: CartItemSearchContext
  }) {
    const {cartItem, saleArticle, isFromCatalog, price, supplierArticle, searchContext} = body

    return this.api.put<ErpResponse<FormObject>>(
      `${this.baseUrl}/cartItems/${cartItem._id}/updateArticle?action=${REPLACE_ARTICLE_ACTION_ID}`,
      {
        saleArticle,
        supplierArticle,
        isFromCatalog,
        price,
        searchContext
      }
    )
  }

  /**
   * Add article to cart
   *
   * @param {string[]} articleIds - articles to add
   * @param {string} cartId - The cart id
   */
  public addToCart(
    articles: {
      saleArticle?: string
      supplierArticle?: string
      price?: PriceBundle
      isFromCatalog: boolean
      searchContext?: CartItemSearchContext
    }[],
    cartId: string
  ) {
    return this.api.post<ErpResponse<FormObject>>(
      `${this.baseUrl}/carts/${cartId}/addArticles?action=${PRODUCT_FINDER_ACTION_ID}`,
      {articles}
    )
  }

  /**
   * Get catalog filtred data
   *
   * @param {Filters} filter - eg: {articleName: 'Le Pin'}
   * @returns {CatalogData} catalog data
   */
  public getProfileSelf() {
    return this.api.get<ErpResponse<Profile>>(`${this.baseUrl}/profiles/self`)
  }

  /**
   * Search sale articles
   *
   * @param {SearchSaleArticles} filters
   * @returns {Array<FormObject>} array of articles
   */
  public searchSaleArticles({product, options, service, cart, action}: SearchSaleArticles) {
    return this.api.post<ErpResponse<SearchArticlesItem[]>>(
      `${this.baseUrl}/carts/${cart._id}/searchSaleArticles?action=${action?._id || action}`,
      {
        product,
        options,
        service: service?._id || service
      }
    )
  }

  /**
   * Search supplier articles
   *
   * @param {SearchSupplierArticles} filters
   * @returns {Array<FormObject>} array of articles
   */
  public searchSupplierArticles(searchArticle: SearchArticlesItem, cartId: string) {
    return this.api.post<ErpResponse<SearchArticlesItem[]>>(
      `${this.baseUrl}/carts/${cartId}/searchSupplierArticles?action=${PRODUCT_FINDER_ACTION_ID}`,
      searchArticle
    )
  }

  /**
   * Toggle tasks by link status
   *
   * @param {Array<string>} tasks
   * @param {string} status - enum: done, obsolete, disabled
   * @param {boolean} value
   * @param {string} tabId
   */
  public updateTasksStatus(
    tasks: string[],
    status: keyof TaskStatus,
    value: boolean,
    tabId: string
  ) {
    return this.api.put<ErpResponse<object>>(`${this.baseUrl}/tasks/updateStatus?tab=${tabId}`, {
      tasks,
      status,
      value
    })
  }

  /**
   * Get checklist for project
   *
   * @param {string} projectId
   * @returns {ChecklistPayload} checklist
   */
  public getChecklist(projectId?: string) {
    return this.api.get<ErpResponse<ChecklistPayload>>(
      `${this.baseUrl}/projects/${projectId}/checklist?tab=63ecde5c213c6b41968fc684`
    )
  }

  /**
   * Get timeline for project
   *
   * @param {string} projectId
   * @returns {TimelineData} timeline
   */
  public getTimeline(projectId?: string) {
    return this.api.get<ErpResponse<TimelineData>>(
      `${this.baseUrl}/projects/${projectId}/timeline?tab=63ecde5c213c6b41968fc684`
    )
  }
}

const ErpApiContext = createContext<ErpApiService | undefined>(undefined)

export const ErpApiProvider = (props: {erpApiService: ErpApiService; children?: ReactNode}) => {
  return (
    <ErpApiContext.Provider value={props.erpApiService}>{props.children}</ErpApiContext.Provider>
  )
}

export const useErpApiService = () => {
  const context = useContext(ErpApiContext)

  if (context === undefined) {
    throw new Error('useErpApiService must be used within a ErpApiProvider')
  }

  return context
}
