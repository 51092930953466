import {AppContext, withApp} from '@store/app'
import {Button, Col, Row, Space, Typography} from 'antd'
import Category from './category'
import {Cart, CartCategory, CartItemsWithSuppliers} from '../cartValidation.t'
import {CheckCircleFilled, SaveOutlined} from '@ant-design/icons'
import {Dispatch, SetStateAction, useCallback, useMemo, useState} from 'react'
import {usePatchMutation} from '@queries'
import {ErpDomain} from '@shared/interfaces'
import {saveDiff} from '@libs/utils'
import {FormObject} from '@components/forms/form.t'
import {useInmemoriServices} from '@services'
import {PanelsKeys} from '@libs/panels/panels.t'

export interface SuppliersValidationProps extends AppContext {
  cart: Cart
  categories: CartCategory[]
  cartItems: CartItemsWithSuppliers[]
  setCartItems: Dispatch<SetStateAction<CartItemsWithSuppliers[]>>
}

const {Text, Title} = Typography

const SuppliersValidation: React.FC<SuppliersValidationProps> = ({
  t,
  CRM,
  toast,
  modal,
  Panels,
  cart,
  categories,
  cartItems,
  setCartItems
}: SuppliersValidationProps) => {
  const [updatedCartItems, setUpdatedCartItems] = useState<string[]>([])

  const erpApi = useInmemoriServices().erpApiService
  const patchItemMutation = usePatchMutation()

  const cartDomain = useMemo<ErpDomain | undefined>(
    () => CRM?.getDomain('cart') as ErpDomain,
    [CRM]
  )

  const cartItemDomain = useMemo<ErpDomain | undefined>(
    () => CRM?.getDomain('cartItem') as ErpDomain,
    [CRM]
  )

  const onSave = useCallback(async () => {
    if (updatedCartItems.length === 0 || !cartItemDomain) return

    return Promise.all(
      updatedCartItems.map((id) => {
        const item = cartItems.find((ci) => ci._id === id)

        if (!item) return

        const {saleArticle, supplierArticle, price, searchContext} = item

        return erpApi.replaceCartItemArticle({
          saleArticle: saleArticle?._id,
          supplierArticle: supplierArticle?._id,
          cartItem: item,
          price,
          searchContext,
          isFromCatalog: false
        })
      })
    )
      .then(() => {
        window.SM?.sub.refresh('cart')
        toast.success('panel.cartValidation.steps.suppliers.save.success')
      })
      .catch((err) => {
        const errorMessage =
          err.response?.data?.data?.err || 'panel.cartValidation.steps.suppliers.save.error'
        toast.error(errorMessage)
      })
  }, [cartItemDomain, cartItems, erpApi, toast, updatedCartItems])

  const showSuccessModal = useCallback(() => {
    modal.confirm({
      title: (
        <Row justify='center'>
          <Title level={4}>{t('panel.cartValidation.steps.suppliers.validate.modal.title')}</Title>
        </Row>
      ),
      content: (
        <Col className='mx-3 mb-4'>
          <Row className='px-3 my-2'>
            <Text strong className='pre-line'>
              {t('panel.cartValidation.steps.suppliers.validate.modal.content')}
            </Text>
          </Row>
        </Col>
      ),
      icon: null,
      okText: t('panel.cartValidation.steps.suppliers.validate.modal.button'),
      onOk: () => {
        Panels.close(PanelsKeys.CART_VALIDATION)
      },
      cancelButtonProps: {style: {display: 'none'}},
      closable: false,
      maskClosable: false,
      width: 400,
      styles: {
        body: {
          padding: '2rem'
        }
      }
    })
  }, [Panels, modal, t])

  const onValidate = useCallback(async () => {
    if (!cartDomain) return

    const diff = saveDiff(
      cartDomain,
      {...(cart as unknown as FormObject)},
      {...(cart as unknown as FormObject), validated: true}
    )

    return Promise.all([
      onSave(),
      patchItemMutation.mutateAsync({domain: cartDomain, id: cart._id, patchs: diff})
    ])
      .then(() => {
        showSuccessModal()
        window.SM?.sub.refresh('cart')
      })
      .catch((err) => {
        const errorMessage =
          err.response?.data?.data?.err || 'panel.cartValidation.steps.suppliers.validate.error'
        toast.error(errorMessage)
      })
  }, [cart, cartDomain, onSave, patchItemMutation, showSuccessModal, toast])

  const canValidate = useMemo(() => {
    return cartItems.every((item) => item.supplierArticle)
  }, [cartItems])

  const canSave = useMemo(() => {
    return !cartItems.every((item) => item.supplierArticles && item.supplierArticles.length > 0)
  }, [cartItems])

  const saveDisabled = useMemo(() => {
    return updatedCartItems.length === 0
  }, [updatedCartItems])

  return (
    <Col className='mt-4 hidden-scroll-container' style={{maxHeight: '600px', overflow: 'scroll'}}>
      <Space direction='vertical' size='middle' className='w-100'>
        {categories.map((category, index) => {
          return (
            <Category
              key={index}
              cart={cart}
              category={category}
              cartItems={cartItems}
              setCartItems={setCartItems}
              updatedCartItems={updatedCartItems}
              setUpdatedCartItems={setUpdatedCartItems}
            />
          )
        })}
        <Row justify='end'>
          <Space direction='horizontal'>
            {canSave && (
              <Button
                type='default'
                onClick={onSave}
                icon={<SaveOutlined />}
                disabled={saveDisabled}>
                {t('panel.cartValidation.steps.suppliers.save.button')}
              </Button>
            )}
            <Button
              type='default'
              onClick={onValidate}
              icon={<CheckCircleFilled />}
              disabled={!canValidate}>
              {t('panel.cartValidation.steps.suppliers.validate.button')}
            </Button>
          </Space>
        </Row>
      </Space>
    </Col>
  )
}

export default withApp(SuppliersValidation)
